<template>
  <div class="home">
    <div class="banner" style="z-index: 0;position: relative;">
      
      <!-- Banner -->
      <div class="slideshow" style="max-height:100vh; overflow:hidden">

        

        <div class="mySlides fade s" style="height:100vh">
            
            <div class="container" style="height:100%; flex-wrap: wrap; flex-direction: column; justify-content: center; display: flex">
                <img :src="require('../assets/images/logo.png')" style="width:250px; padding-bottom:20px " alt="">
                <h3 style="font-weight: bold; color:white">Há mais de 20 anos a fabricar peças únicas</h3>
            </div>
            
        </div>

        <!-- <div class="mySlides fade t" style="height:100vh">
            
            
            <div class="text">Caption Three</div>
        </div> -->

        <!-- <a class="prev" @click="plusSlides(-1)">❮</a>
        <a class="next" @click="plusSlides(1)">❯</a> -->


      </div>
    </div>

    <!-- Top Pieces -->
    <div class="container">
      <h4 class=" text-center pb-4">Peças Recentes</h4>
      <section class="top-quizzes">
        <div class="row pb-4">
          <div class="col-md-3 col-6" v-for="i in (recentes.length)" :key="i" @click="$router.push({ name: 'Piece', params: { sku: recentes[i-1].sku } })">
            <div class="tile__media" style="cursor:pointer">
              <img class="tile__img" :src="require('../assets/images/pecas/'+recentes[i-1].img[0])"/>
              <p style="color:black">{{ recentes[i-1].titulo }}</p>
            </div>
            
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        artigos:null,
        recentes:[],
        slideIndex: 1
      }
    },
    created() {
      this.artigos=this.getAllArtigos;
      this.getRecentes();
    },
    mounted () {
      this.showSlides();
    },
    computed: {
      ...mapGetters(["getAllArtigos"]),
    },
    methods: {
      plusSlides(n) {
          this.showSlides(this.slideIndex += n);
      },
      currentSlide(n) {
          this.showSlides(this.slideIndex = n);
      },
      showSlides(n) {
          let i;
          let slides = document.getElementsByClassName("mySlides");
          let dots = document.getElementsByClassName("dot");
          if (n > slides.length) {this.slideIndex = 1}    
          if (n < 1) {this.slideIndex = slides.length}
          for (i = 0; i < slides.length; i++) {
              slides[i].style.display = "none";  
          }
          for (i = 0; i < dots.length; i++) {
              dots[i].className = dots[i].className.replace(" active", "");
          }
          slides[this.slideIndex-1].style.display = "block";  
          dots[this.slideIndex-1].className += " active";
      },
      getRecentes(){
        for (let i = this.artigos.length-1; i >= this.artigos.length-4; i--) {
          this.recentes.push(this.artigos[i])
        }
      },
    }
  };
</script>

<style scoped>
  .texto-banner {
    display: flex;
    padding: 0px 80px;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .p{
        background:linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('../assets/images/bannerPorto.jpg');
        background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    }
    .s{
        background:linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('../assets/images/capanova.webp');
        background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    }
    .t{
        background:linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('../assets/images/bannerPorto.jpg');
        background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    }
.mySlides {display: none}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  max-height: 100px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}


/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}
.fade:not(.show) {
    opacity:1;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next,.text {font-size: 11px}
}
.home{
  background-color: #f8f9fa;
}
.banner{
  top:-80px;
}

  a {
    text-decoration: none;
    color: white;
  }



  

 

  .tile {
    position: relative;
    display: inline-block;
    /* max-width: 200px; */
    width: 100%;
    margin-right: 10px;
    font-size: 20px;
  }

  .tile__media img {
    /* max-width: 200px; */
    width: 100%;
    height: 230px;
  }

  .tile__img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 5px;
  }

  .tile:hover .tile__details {
    opacity: 1;
  }

  .tile__details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
    transition: 450ms opacity;
    border-radius: 5px;
  }

  .tile__details:after {
    margin-top: -25px;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    border: 3px solid #ecf0f1;
    line-height: 50px;
    text-align: center;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .tile__details:before:hover {
    cursor: pointer;
  }

  .tile:hover .tile__details {
    opacity: 1;
  }

  .tile__details .quiz-card-rating {
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 35px;
    height: 35px;
    border: 2px solid var(--verde);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: .85em;
    color: var(--verde);
  }

  .tile__details .quiz-card-title {
    white-space: normal;
  }

  .tile__details .quiz-card-play {
    position: absolute;
    top: 130px;
    left: 82px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 50%;
  }

  .tile__details .quiz-card-play:hover {
    cursor: pointer;
  }

  @media (max-width: 419px) {

    .tile {
      max-width: 150px;
    }

    .tile__media img {
      height: 222.22px;
    }

    .tile__details .quiz-card-play {
      top: 96px;
      left: 58px;
    }
  }

  @media (max-width: 767px) {
    .texto-banner {
    display: flex;
    padding: 0px 80px;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .p{
        background:linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.9)), url('../assets/images/poster2mobile.png');
        background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    }
    
  }
  @media (max-width: 991px) {
  .banner{
    top:-98px;
  }
  }
</style>
