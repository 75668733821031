import Vue from "vue";
import Vuex from "vuex";

// Popular a localstorage com informação pré-determinada
import artigos from "../assets/data/artigos.json";
import dataGenero from "../assets/data/genero.json";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    artigos:localStorage.artigos?JSON.parse(localStorage.artigos):artigos,
    genero: localStorage.genero ? JSON.parse(localStorage.genero) : dataGenero,
  },
  getters: {
    getPieceBySKU:(state)=>(sku)=>state.artigos.find((piece)=>piece.sku==sku),
    getAllArtigos:(state)=>state.artigos,
    getAllGenres: (state) => state.genero,
    
  },
  actions:{
  },
  mutations: {
  }
});
