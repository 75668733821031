<template>
  <div id="footer" class="">
    <div  class="container d-flex flex-row flex-wrap">
      <div class="col-md-4 col-12">
        <h5>Suporte</h5>
        <ul style="list-style-type: none;">
          <li><router-link :to="{ name: 'Guia' }">Guia de tamanhos</router-link></li>
        </ul>
      </div>
      <div class="col-md-4 col-12">
        <h5>Informações uteis </h5>
        <ul style="list-style-type: none;">
          <li> <router-link :to="{ name: 'Contactos' }">Contactos</router-link></li>
          <li> <router-link :to="{ name: 'SobreNos' }">Sobre Nós</router-link></li>
        </ul>
      </div>
      <div class="col-md-4 col-12">
        <h5>Siga-nos</h5>
        <div class="d-flex" style="padding-top:10px;">
          <div style="margin-right:10px;">
            <a href="https://www.facebook.com/amagjoias/" target="_blank"><i class="fab fa-facebook" style="padding: 10px; border: 1px solid black; border-radius: 20px;"></i></a>
          </div>
            <a href="https://www.instagram.com/amagjoias/?__coig_restricted=1" target="_blank"><i class="fab fa-instagram" style="padding: 10px; border: 1px solid black; border-radius: 20px;"></i></a>
        </div>
        
      </div>

    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
  #footer {
    background-color: #f1f1f1;
    padding: 40px;
    width: 100%;
  }

  #social {
    gap: 50px;
  }

  #social i {
    color: white;
  }
  #footer a{
     text-decoration: none;
     color:black;
  }
  #footer ul{
        padding-left: 0;
  }
  #footer li{
        padding-top: 10px;
  }
</style>