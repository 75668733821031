import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Router from 'vue-router'
import routes from "./router/index";
import store from "./store";
import VueSweetalert2 from 'vue-sweetalert2';

Vue.config.productionTip = false;

// Importing the global css file
import "@/assets/css/global.css"
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

// const routes = new Router({
// 	mode: 'history',
// 	base: process.env.BASE_URL,
// 	routes,
// });

// new Vue({ routes, render: h => h(App) }).$mount('#app');
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
