<template>  
    <div id="peca" class="d-flex flex-wrap">
        <div class="col-12 col-md-6 piece-div" style="color:black; display: flex; justify-content: center; flex-direction: column; flex-wrap: wrap; align-items: flex-start; align-content: center;">
            <h1><b>{{peca.titulo}}</b></h1> 
            <h4 class="mt-3" style="width:50%">{{peca.descricão}}</h4>
            <h4 class="mt-4">Materia prima: {{peca.materiaPrima}}</h4>
            <div v-if="peca.pedras.length>0" >
                <h4 v-for="i in peca.pedras.length" :key="i" >Pedras: {{peca.pedras[i-1]}}</h4>
            </div>
            <h4>SKU: {{peca.sku}}</h4>
            
        </div>
        <div class="col-12 col-md-6 d-flex justify-center image-piece" style="position:relative ;justify-content: center;">
           
                <img id="img" :src="require('../assets/images/pecas/'+peca.img[0])" alt="0" style="width:auto; object-fit:cover; overflow: hidden; min-width:100%">
                <div style=" position: absolute; bottom:20px;">
                    <button id="before" @click="changePhoto(0)" style="background: #6a6a6a52; color: white; border: 2px solid white; margin-right: 20px; padding: 10px;">PARA TRÁS</button>  
                    <button id="next" @click="changePhoto(1)" style="background: #6a6a6a52; color: white; border: 2px solid white; margin-left: 20px; padding: 10px;">SEGUINTE</button>  
                </div>
        </div>
        
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
    export default {
        data() {
            return {
                imagem:0,
                peca:null
            }
        },
        mounted () {
            document.getElementById("before").style.visibility = "hidden";
            if (this.peca.img.length-1==0) {
               document.getElementById("next").style.visibility = "hidden"; 
            }
        },
        created () {
            this.peca= this.getPieceBySKU(this.$route.params.sku);
            
        },
        
        methods: {
            ...mapMutations([]),
            changePhoto(p){
                if(p==1){
                    if (this.imagem<this.peca.img.length) {
                        this.imagem+=1;
                        document.querySelector("#img").src = require('../assets/images/pecas/'+this.peca.img[this.imagem]);
                        document.getElementById("img").alt=this.imagem;
                    }
                }
                else{
                    if (this.imagem>=0) {
                        this.imagem-=1;
                        document.querySelector("#img").src = require('../assets/images/pecas/'+this.peca.img[this.imagem]);
                        document.getElementById("img").alt=this.imagem
                    }
                }
            }
        },
        computed: {
            ...mapGetters(["getPieceBySKU"])
        },
        
        watch: {
            'imagem'() {
                if (this.peca.img.length-1==this.imagem) {
                    document.getElementById("next").style.visibility = "hidden";
                }
                else{
                    document.getElementById("next").style.visibility = "visible";
                }
                if (0==this.imagem) {
                    document.getElementById("before").style.visibility = "hidden";
                }
                else{
                    document.getElementById("before").style.visibility = "visible";
                }
            },
            '$route.params.sku'(){
                this.peca= this.getPieceBySKU(this.$route.params.sku);
                if (this.peca.img.length-1==this.imagem) {
                    document.getElementById("next").style.visibility = "hidden";
                }
                else{
                    document.getElementById("next").style.visibility = "visible";
                }
                if (0==this.imagem) {
                    document.getElementById("before").style.visibility = "hidden";
                }
                else{
                    document.getElementById("before").style.visibility = "visible";
                }
            }
        },
    }
</script>

<style scoped>

#peca{
        flex-direction: row !important;
    }
.piece-div{
    background-color:#f8f9fa;
    height: 90vh;
}
a {
    color: white;
    text-decoration: none;
}

.correct-answer {
    background-color: #1DB83C !important;
    transition: 450ms;
}

.wrong-answer {
    background-color: #EC5252 !important;
    transition: 450ms;
}

.selected-answer {
    background-color: #4d535e !important;
    transition: 450ms;
}

.questions{
    border-radius: 10px;
    background-color: var(--bg);
    cursor: pointer;
}

.quizImage{
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center top;
}

.unblock-button {
  background: var(--vermelho);
  border-radius: 5px;
  color: #ffffff;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.quizHeader {
    background-color: #282F3C;
}

#rating{
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color:var(--verde);
    border: 3px solid var(--verde);
      
}

.orangeText{
    color: var(--laranja);
}

#banner h3{
    font-weight: bold;
    font-size: 55px;
}

.bannerQuizz {
    min-height: 350px;
    height: 100%;
}

.jumbotron {
    background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url("https://i.pinimg.com/originals/91/0d/67/910d67f4f41a771e3f5f0c50c8f8dd0e.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}

#comment-content {
  resize: none;
  width: 100%;
  height: 85px;
  background-color: var(--cinza1);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px;
}

#comment-content:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

#comments label {
  color: var(--cinza2);
}

.card-comment .photo {
  min-width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.card-comment #comment-photo1 {
  background-image: url("https://thispersondoesnotexist.com/image");
}

.card-comment #comment-photo2 {
  background-image: url("https://cdn.discordapp.com/attachments/819526744419598356/916005827469512764/netflix.png");
}

#comment-author {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

#comment-message {
  color: #EEEEEE;
}

.card-comment .details span {
  margin-left: 15px;
  color: var(--cinza2);
}

.fix-margin {
    margin-top: -265px;
}
.image-piece{
        height:90vh !important;
}
@media (max-width: 1199px) {
    .fix-margin {
        margin-top: -385px;
    }

    .quiz-title {
        font-size: 2.5rem !important;
    }

    .quiz-description {
        font-size: 1.5rem !important;
    }
}

@media (max-width: 991px) {
    .fix-margin {
        margin-top: 0;
    }
    #peca{
        flex-direction: column-reverse !important;
    }
    .image-piece{
        height:40vh !important;
    }
    .piece-div{
        height:55vh !important;
    }
}

@media (max-width: 575px) {
    .jumbotron {
        max-height: 450px !important;
    }

    .help-section {
        flex-direction: column;
    }

    .quiz-title {
        font-size: 2rem !important;
    }

    .quiz-description {
        font-size: 1.5rem !important;
    }
}

@media (max-width: 419px) {
    .jumbotron {
        max-height: 525px !important;
    }

    .quiz-title {
        font-size: 1.5rem !important;
    }

    .quiz-description {
        font-size: 1rem !important;
    }
}
</style>