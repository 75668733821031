<template> 
    <div >
        <div class="slideshow" style="max-height:100vh; overflow:hidden">

            <div class="mySlides fade p" style="height:100vh">
                <div class="container d-flex" style="height:100%;justify-content: center;align-items: end;">
                    <h1 style="font-weight: bold; color:white">Iremos estar na Portojóia dos dias 22 a 25 de Setembro</h1>
                </div>
            </div>

            <div class="mySlides fade s" style="height:100vh">
                
                <div class="container" style="padding-bottom:220px">
                    <img :src="require('../assets/images/logo.png')" style="width:250px; padding-bottom:20px " alt="">
                    <h3 style="font-weight: bold; color:white">Há mais de 20 anos a fabricar peças únicas</h3>
                </div>
                
            </div>

            <div class="mySlides fade t" style="height:100vh">
                
                
                <div class="text">Caption Three</div>
            </div>

            <a class="prev" @click="plusSlides(-1)">❮</a>
            <a class="next" @click="plusSlides(1)">❯</a>

           
        </div>
        <br>

        
    </div> 
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
    export default {
        data() {
            return {
                slideIndex: 1
            }
        },
        mounted () {
            this.showSlides();
        },
        created () {
            
        },
        methods: {
            plusSlides(n) {
                this.showSlides(this.slideIndex += n);
            },
            currentSlide(n) {
                this.showSlides(this.slideIndex = n);
            },
            showSlides(n) {
                let i;
                let slides = document.getElementsByClassName("mySlides");
                let dots = document.getElementsByClassName("dot");
                if (n > slides.length) {this.slideIndex = 1}    
                if (n < 1) {this.slideIndex = slides.length}
                for (i = 0; i < slides.length; i++) {
                    slides[i].style.display = "none";  
                }
                for (i = 0; i < dots.length; i++) {
                    dots[i].className = dots[i].className.replace(" active", "");
                }
                slides[this.slideIndex-1].style.display = "block";  
                dots[this.slideIndex-1].className += " active";
            }
        },
        computed: {
            
        }
    }
</script>

<style scoped>
    .p{
        background:linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('../assets/images/bannerPorto.jpg');
        background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    }
    .s{
        background:linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('../assets/images/capanova.webp');
        background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    }
    .t{
        background:linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('../assets/images/bannerPorto.jpg');
        background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    }
.mySlides {display: none}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  max-height: 100px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}


/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}
.fade:not(.show) {
    opacity:1;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next,.text {font-size: 11px}
}
</style>