<template>
  <div v-if="currentWidth >= 992" id="desktop">
    <nav v-if="$route.name=='Home'"  class="navbar navbar-expand-lg" style="z-index:1;">
      <div class="container">
        
        <div class="col-4">
          <div class="">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 justify-content-left">
              <li class="nav-item" style="margin-right:20px;">
                <router-link class="nav-link active" :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="nav-item" style="margin-right:20px;">
                <router-link class="nav-link" :to="{ name: 'Catalog' }">Catálogo</router-link>
              </li>
              <li class="nav-item" style="margin-right:20px;">
                <router-link class="nav-link" :to="{ name: 'SobreNos' }">Sobre Nós</router-link>
              </li>
            </ul>
          </div>
          
        </div>
        <div class="col-4" style="text-align:center">
          <router-link class="navbar-brand" :to="{ name: 'Home' }" style="width:10%">
            <img :src="require('../assets/images/logo.png')" style="width:25%" alt="Logo" />
          </router-link>
        </div>
        <div class="col-4">
          <div class="d-flex justify-content-end">
            <form @submit.prevent="" class="me-4 position-relative">
              <div class="input-group">
                <input id="search-bar" class="placeWhite" type="search" placeholder="Procure algo" aria-label="Search" v-model="search" style="color:white;">
                <button type="submit" ><i class="fas fa-search"></i></button>
              </div>
              <div tabindex="0" class="leaderboardBar" id="search-results"
                style="
                  display: none;
                  background-color: rgb(112 104 99);
                  max-height: 178px;
                  position: absolute;
                  top: 45px;
                  width: 100%;
                  z-index: 1;
                  border-radius: 5px;
                  overflow-y: auto;">
                <div class="card-comment p-3 me-1" v-for="i in filteredTitles.length" :key="i">
                  <div class="d-flex w-100 align-items-center" style="gap: 15px; cursor: pointer;" @click="redirect(filteredTitles[i - 1])">
                    <div style="width: 50px; height: 73px; min-width: 50px; min-height: 73px;">
                      <img class="w-100 h-100" style="object-fit: cover; object-position: center top; border-radius: 5px;" :src="require('../assets/images/pecas/'+filteredTitles[i - 1].img[0])">
                    </div>
                    <div class="d-flex flex-column">
                      <p style="color: white; font-size: 0.9rem;" class="m-0"><strong>{{ filteredTitles[i - 1].titulo }}</strong></p>
                    </div>
                  </div>
                </div>
                <div class="card-comment p-3 me-1" v-if="filteredTitles.length == 0">
                  <p style="color: white; font-size: 0.9rem;" class="m-0"><strong>Nenhum resultado encontrado.</strong></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </nav>
    <nav v-else class="navbar navbar-expand-lg" style="z-index:1; background:white; box-shadow: 3px 3px #8f8f8f05;">
      <div class="container">
        <div class="col-4">
          <div class="">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 justify-content-left" >
              <li class="nav-item" style="margin-right:20px;">
                <router-link style="color:black;" class="nav-link active" :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="nav-item" style="margin-right:20px;">
                <router-link style="color:black;" class="nav-link" :to="{ name: 'Catalog' }">Catálogo</router-link>
              </li>
              <li class="nav-item" style="margin-right:20px;">
                <router-link style="color:black; !importante" class="nav-link" :to="{ name: 'SobreNos' }">Sobre Nós</router-link>
              </li>
            </ul>
          </div>
          
        </div>
        <div class="col-4" style="text-align:center">
          <router-link class="navbar-brand" :to="{ name: 'Home' }" style="width:10%">
            <img :src="require('../assets/images/logo.png')" style="width:25%; filter: invert(1);" alt="Logo" />
          </router-link>
        </div>
        <div class="col-4">
          <div class="d-flex justify-content-end">
            <form @submit.prevent="" class="me-4 position-relative">
              <div class="input-group">
                <input id="search-bar" type="search" style="border-color:black" placeholder="Pesquise algo" aria-label="Search" v-model="search">
                <button type="submit" style="background-color:black"><i class="fas fa-search" style="color:white"></i></button>
              </div>
              <div tabindex="0" class="leaderboardBar" id="search-results"
                style="
                  display: none;
                  background-color: rgb(112 104 99);
                  max-height: 178px;
                  position: absolute;
                  top: 45px;
                  width: 100%;
                  z-index: 1;
                  border-radius: 5px;
                  overflow-y: auto;">
                <div class="card-comment p-3 me-1" v-for="i in filteredTitles.length" :key="i">
                  <div class="d-flex w-100 align-items-center" style="gap: 15px; cursor: pointer;" @click="redirect(filteredTitles[i - 1])">
                    <div style="width: 50px; height: 73px; min-width: 50px; min-height: 73px;">
                      <img class="w-100 h-100" style="object-fit: cover; object-position: center top; border-radius: 5px;" :src="require('../assets/images/pecas/'+filteredTitles[i - 1].img[0])">
                    </div>
                    <div class="d-flex flex-column">
                      <p style="color: white; font-size: 0.9rem;" class="m-0"><strong>{{ filteredTitles[i - 1].titulo }}</strong></p>
                    </div>
                  </div>
                </div>
                <div class="card-comment p-3 me-1" v-if="filteredTitles.length == 0">
                  <p style="color: white; font-size: 0.9rem;" class="m-0"><strong>Sem resultados encontrados.</strong></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </nav>
  </div>
  

  <div v-else id="mobile">
    <nav class="navbar navbar-expand-lg" id="navbarMobile" style="z-index:1;">
      <div class="" style="padding: 0px 15px; width: 100%;">
        <div style="display: flex;    align-items: center;     justify-content: space-between;">
          <router-link class="navbar-brand" :to="{ name: 'Home' }">
            <img v-if="$route.name=='Home'" id="logoHomeMobile" :src="require('../assets/images/logo.png')" style="width:40%;" alt="Logo" />
            <img v-else :src="require('../assets/images/logo.png')" style="width:40%; filter: invert(1);" alt="Logo" />
          </router-link>
          <button class="nav-btn-toggler navbar-toggler" @click="abrirMenu();" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
          </button>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          
          <form @submit.prevent="" class="position-relative">
            <div class="input-group mb-3">
              <input id="search-bar-mobile" type="search" class="form-control" placeholder="Pesquise algo" aria-label="Search" v-model="search">
              <button type="submit" style="background-color:black; "><i class="fas fa-search" style="color:white;"></i></button>
            </div>
            <div tabindex="0" class="leaderboardBar" id="search-results-mobile"
              style="
                display: none;
                background-color: rgb(112 104 99);
                max-height: 178px;
                position: absolute;
                top: 45px;
                width: 100%;
                z-index: 1;
                border-radius: 5px;
                overflow-y: auto;">
              <div class="card-comment p-3 me-1" v-for="i in filteredTitles.length" :key="i">
                <div class="d-flex w-100 align-items-center" style="gap: 15px; cursor: pointer;" @click="redirect(filteredTitles[i - 1])">
                  <div style="width: 50px; height: 73px; min-width: 50px; min-height: 73px;">
                    <img class="w-100 h-100" style="object-fit: cover; object-position: center top; border-radius: 5px;" :src="require('../assets/images/pecas/'+filteredTitles[i - 1].img[0])">
                  </div>
                  <div class="d-flex flex-column">
                    <p style="color: white; font-size: 0.9rem;" class="m-0"><strong>{{ filteredTitles[i - 1].titulo }}</strong></p>
                  </div>
                </div>
              </div>
              <div class="card-comment p-3 me-1" v-if="filteredTitles.length == 0">
                <p style="color: white; font-size: 0.9rem;" class="m-0"><strong>Nenhum resultado encontrado.</strong></p>
              </div>
            </div>
            
          </form>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0" style="color:black">
            <li class="nav-item">
              <router-link class="nav-link active" :to="{ name: 'Home' }">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'Catalog' }">Catálogo</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'SobreNos' }">Sobre Nós</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      searchActive:false,
      currentWidth: window.innerWidth,
      search: "",
      changeMenuColor:"fechado"
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.insertEventListeners();
  },
  methods: {
    onResize() {
      this.currentWidth = window.innerWidth;
      this.insertEventListeners();
    },
    abrirMenu(){
      if(this.changeMenuColor=="aberto"){
        document.getElementById("navbarMobile").style.background="transparent"
        document.getElementById("logoHomeMobile").style.filter="invert(0)"
        this.changeMenuColor="fechado"
      }else{
        document.getElementById("navbarMobile").style.background="white"
        document.getElementById("logoHomeMobile").style.filter="invert(1)"
        this.changeMenuColor="aberto"
      }
    },
    redirect(obj) {
      this.$router.push({ name: 'Piece', params: { sku: obj.sku} });
      document.querySelector("#search-results").style.display = "none";
    },
    insertEventListeners() {
      try {
        document.querySelector("#search-bar").addEventListener("focusout", (event) =>  document.querySelector("#search-results").style.display = event.relatedTarget != document.querySelector("#search-results") ?  "none" : "block");
        document.querySelector("#search-bar").addEventListener("focusin", () => this.search.length >= 3 ? document.querySelector("#search-results").style.display = "block" : document.querySelector("#search-results").style.display = "none" );
      } catch { 
        document.querySelector("#search-bar-mobile").addEventListener("focusout", (event) =>  document.querySelector("#search-results-mobile").style.display = event.relatedTarget != document.querySelector("#search-results-mobile") ?  "none" : "block");
        document.querySelector("#search-bar-mobile").addEventListener("focusin", () => this.search.length >= 3 ? document.querySelector("#search-results-mobile").style.display = "block" : document.querySelector("#search-results-mobile").style.display = "none" );
      };
    }
  },
  computed: {
    ...mapGetters([ "getAllArtigos"]),
    filteredTitles() {
      let filterResult = [...this.getAllArtigos];
    

      // Barra de pesquisa
      filterResult = filterResult.filter(piece => piece.titulo.toLowerCase().includes(this.search.toLowerCase()));

      
    
      return filterResult.length > 5 ? filterResult.slice(0, 5) : filterResult;
    }
  },
  watch:{
    $route() {
      if (this.currentWidth < 992) {
        document.querySelector(".nav-btn-toggler").setAttribute("aria-expanded", false);
        document.querySelector(".nav-btn-toggler").classList = "nav-btn-toggler navbar-toggler collapsed";
        document.querySelector("#navbarSupportedContent").classList = "navbar-collapse collapse";
      };
      this.changeMenuColor="fechado"
      document.getElementById("navbarMobile").style.background="transparent"
      document.getElementById("logoHomeMobile").style.filter="invert(0)"
    },
    search() {
      try {
        if (this.search.length >= 3) {
          document.querySelector("#search-results").style.display = "block";
        } else {
          document.querySelector("#search-results").style.display = "none";
        }
      } catch {
        if (this.search.length >= 3) {
          document.querySelector("#search-results-mobile").style.display = "block";
        } else {
          document.querySelector("#search-results-mobile").style.display = "none";
        }
      };

    },
    
  } 
};
</script>

<style scoped>
  #desktop nav {
    background-color: transparent;
    height: 80px;
  }

  #desktop a{
    color: white;
    font-weight: bold;
    text-decoration: none;
  }
  #mobile a {
    color: black;
    font-weight: bold;
    text-decoration: none;
  }

  span {
    color: var(--cinza2);
    font-size: 0.75em;
  }

  .leaderboardBar::-webkit-scrollbar {
    width: 5px;
  }

  .leaderboardBar::-webkit-scrollbar-thumb {
    background: #464646;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  .leaderboardBar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }
  
  .placeWhite::placeholder {
    color: rgba(255, 255, 255, 0.678) !important;
    opacity: 1;
  }

  .placeWhite:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.678);
    opacity: 1;
  }

  .placeWhite::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.678);
    opacity: 1;
  }

  form input {
    border: none;
    outline: none;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 35px;
    color: black;
    padding: 10px 15px 10px 15px;
  }

  form button {
    border: none !important;
    background: white;
    color:black;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    padding: 0 15px 0 15px;
  }

  #mobile form input,
  #mobile form input:focus {
    border-color: black;
    color: black;
    outline: none !important;
    box-shadow: none;
    background-color: transparent;
  }

  form i {
    color: black;
  }

  #username {
    color: white;
    font-weight: bold;
  }

  #desktop #username a {
    margin: 0;
    padding: 0;
  }
  
  #desktop #level {
    position: relative;
    top: 15px;
    right: 10px;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
  }

  #level span {
    display: inline-block;
    padding: 0;
    margin: 0;
    padding: 2px;
    font-weight: bold;
  }

  #mobile nav {
    background-color: transparent;
  }

  #mobile .navbar-toggler {
    background-color: white;
    border: none;
  }

  #mobile .navbar-toggler:focus {
    outline: none !important;
    box-shadow: none;
  }

  #mobile .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(24, 33, 49)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }

  #mobile .input-group {
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>