import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Catalog from "../views/Catalog.vue";
import Error from "../views/Error.vue";
import store from "../store/index.js";
import Piece from "../views/Piece.vue";
import SobreNos from "../views/SobreNos.vue";
import Contactos from "../views/Contactos.vue";
import Guia from "../views/Guia.vue";
import Teste from "../views/teste.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    
  },
  {
    path: "/piece/:sku",
    name: "Piece",
    component: Piece,
  },
  {
    path: "/catalog",
    name: "Catalog",
    component: Catalog,
  },
  {
    path: "/sobreNos",
    name: "SobreNos",
    component: SobreNos,
  },
  {
    path: "/contactos",
    name: "Contactos",
    component: Contactos,
  },
  {
    path: "/guia",
    name: "Guia",
    component: Guia,
  },
  {
    path: "/teste",
    name: "Teste",
    component: Teste,
  },
  {
    path: "*",
    name: "Error",
    component: Error
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  next();
  
});

export default router;
