<template>
  <div id="app" :class="{ fullheight: $route.name == 'Error' }">
    <Navbar/>
    <router-view :class=" ['Error'].indexOf($route.name) == -1 ? 'view' : 'fullheight'"/>
    <meta name="google-site-verification" content="KaYU8QgMc001o5qKYFcdphHjG2tqw8SEov7o2HN8Vzk" />
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Navbar,
    Footer
  }
}
</script>

<style>
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    
  }
  ::-webkit-scrollbar-thumb {
    background: black; 
    border-radius: 0px;
    transition-duration: 0.6s;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #FFA500;
    transition-duration: 2s; 
  }
  #app,
  html,
  body {
    background: white;
    color: black;
  }
  .view {
    min-height: calc(100vh - 265px);
  }
  .fullheight {
    min-height: 100vh;
  }
  @media (max-width: 991px) {
    .view {
      min-height: calc(100vh - 241px);
    }
  }
</style>