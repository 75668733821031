<template>  
    <div id="peca" class="container d-flex flex-wrap" style="justify-content: center; align-content: center;">
        <div class="col-12 mb-4" style="color:black; display:flex;  padding-top:30px; text-align:center;flex-wrap: wrap;flex-direction: column;    align-content: center; ">
            <p style="padding:0; margin:0">Guia de tamanhos</p>
            <br>
            <h3 class="pb-4">Não sabe a sua medida? Encontre aqui!</h3> 
            <p>É medido através do diâmetro do dedo. <img :src="require('../assets/images/diametro.jpg')" alt=""> </p>
        </div>
        <img :src="require('../assets/images/guia.jpg')" style="width: 50%" alt="">
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
    export default {
        data() {
            return {
               
            }
        },
       
        
        methods: {
            
        },
        computed: {
            
        },
    }
</script>

<style scoped>
#peca{
        flex-direction: row !important;
    }
.piece-div{
    background-color:#f8f9fa;
}
a {
    color: white;
    text-decoration: none;
}

.correct-answer {
    background-color: #1DB83C !important;
    transition: 450ms;
}

.wrong-answer {
    background-color: #EC5252 !important;
    transition: 450ms;
}

.selected-answer {
    background-color: #4d535e !important;
    transition: 450ms;
}

.questions{
    border-radius: 10px;
    background-color: var(--bg);
    cursor: pointer;
}

.quizImage{
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center top;
}

.unblock-button {
  background: var(--vermelho);
  border-radius: 5px;
  color: #ffffff;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.quizHeader {
    background-color: #282F3C;
}

#rating{
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color:var(--verde);
    border: 3px solid var(--verde);
      
}

.orangeText{
    color: var(--laranja);
}

#banner h3{
    font-weight: bold;
    font-size: 55px;
}

.bannerQuizz {
    min-height: 350px;
    height: 100%;
}

.jumbotron {
    background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url("https://i.pinimg.com/originals/91/0d/67/910d67f4f41a771e3f5f0c50c8f8dd0e.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}

#comment-content {
  resize: none;
  width: 100%;
  height: 85px;
  background-color: var(--cinza1);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px;
}

#comment-content:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

#comments label {
  color: var(--cinza2);
}

.card-comment .photo {
  min-width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.card-comment #comment-photo1 {
  background-image: url("https://thispersondoesnotexist.com/image");
}

.card-comment #comment-photo2 {
  background-image: url("https://cdn.discordapp.com/attachments/819526744419598356/916005827469512764/netflix.png");
}

#comment-author {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

#comment-message {
  color: #EEEEEE;
}

.card-comment .details span {
  margin-left: 15px;
  color: var(--cinza2);
}

.fix-margin {
    margin-top: -265px;
}
.image-piece{
        height:90vh !important;
}
@media (max-width: 1199px) {
    .fix-margin {
        margin-top: -385px;
    }

    .quiz-title {
        font-size: 2.5rem !important;
    }

    .quiz-description {
        font-size: 1.5rem !important;
    }
}

@media (max-width: 991px) {
    .fix-margin {
        margin-top: 0;
    }
    #peca{
        flex-direction: column-reverse !important;
    }
    .image-piece{
        height:80vh !important;
    }
}

@media (max-width: 575px) {
    .jumbotron {
        max-height: 450px !important;
    }

    .help-section {
        flex-direction: column;
    }

    .quiz-title {
        font-size: 2rem !important;
    }

    .quiz-description {
        font-size: 1.5rem !important;
    }
}

@media (max-width: 419px) {
    .jumbotron {
        max-height: 525px !important;
    }

    .quiz-title {
        font-size: 1.5rem !important;
    }

    .quiz-description {
        font-size: 1rem !important;
    }
}
</style>