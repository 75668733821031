<template >
  <div class="catalog-div">
    <div class="container pt-4">
      <!-- Seletor filme/serie -->
      <div class="d-flex justify-content-center flex-wrap" style="gap: 15px;">
        <div :class="selectedType == 1 ? 'active-type' : 'default-type'" @click="selectedType = 1;">
          <span style="font-size: 1.25em;">Aneis</span>
        </div>
        <div :class="selectedType == 2 ? 'active-type' : 'default-type'" @click="selectedType = 2;">
          <span style="font-size: 1.25em;">Alianças</span>
        </div>
        <div :class="selectedType == 3 ? 'active-type' : 'default-type'" @click="selectedType = 3;">
          <span style="font-size: 1.25em;">Brincos</span>
        </div>
        <div :class="selectedType == 4 ? 'active-type' : 'default-type'" @click="selectedType = 4;">
          <span style="font-size: 1.25em;">Colares</span>
        </div>
        <div :class="selectedType == 5 ? 'active-type' : 'default-type'" @click="selectedType = 5;">
          <span style="font-size: 1.25em;">Medalhas</span>
        </div>
        <div :class="selectedType == 6 ? 'active-type' : 'default-type'" @click="selectedType = 6;">
          <span style="font-size: 1.25em;">Pulseiras</span>
        </div>
        <div :class="selectedType == 7 ? 'active-type' : 'default-type'" @click="selectedType = 7;">
          <span style="font-size: 1.25em;">Escravas</span>
        </div>
        
        
      </div>
    </div>

    <div class="container p-0">
      <!-- filtros -->
      <div style=" border-radius: 10px;" class="row g-3 m-0 pb-3 mt-5 mb-3 ">
        <div class="col-md-12 col-lg-4 col-sm-12">
          <form @submit.prevent="" class="d-flex ">
            <div class="input-group">
              <input type="search" class="form-control" style="height: 40px;" placeholder="Pesquisar" aria-label="Search" v-model="filters.search">
              <button type="button"><i class="fas fa-search"></i></button>
            </div>
          </form>
        </div>

        <div class="col-md-3 col-lg-4 col-sm-6 col-6">
          <select id="genre" style="height: 40px;" v-model="filters.materials">
            <option disabled value="Materiais">Materiais</option>
            <option value="All">Todos</option>
            <option :value=genero v-for="(genero, index) in generos" :key="index">{{ genero }}</option>
          </select>
        </div>

        
      </div>
    </div>

    <div class="container">
    <!-- mostrar todos -->
    <div>
      <div class="row g-3">
        <div class="col-md-3 col-sm-4 col-6"  v-for="i in (filteredTitles.length >= mostrar ? mostrar : filteredTitles.length)" :key="i">
          <router-link :to="{ name: 'Piece', params: { sku: filteredTitles[i - 1].sku }} ">
            <div class="tile-custom">
              <div class="tile__media-custom">
                <img class="tile__img"
                  style="max-height: 230px; object-fit: cover; object-position: center;"
                  :src="require('../assets/images/pecas/'+filteredTitles[i - 1].img[0])"
                  rel="preload" />
                  <p class="quiz-card-title">{{filteredTitles[i - 1].titulo}}</p>
              </div>
              <!-- <div class="tile__details p-2 text-center d-flex justify-content-center align-items-center flex-column">
                <p class="quiz-card-title">{{filteredTitles[i - 1].titulo}}</p>
              </div> -->
            </div>
          </router-link>
        </div>

        <div v-if="filteredTitles.length == 0">
          <p>Sem resultados para os filtros aplicados.</p>
        </div>
      </div>
      <div v-if="mostrar < filteredTitles.length" class="w-100 d-flex justify-content-center mt-4">
        <button class="rounded-btn" @click="mostrar = mostrar + 12 <= filteredTitles.length ? mostrar + 12 : filteredTitles.length">Load More</button>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
 import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        selectedType: 1,
        mostrar: 600,
        anos: [],
        generos: [],
        paises: [],
        filters: {
          search: "",
          materials: "Materiais",
          orderby: "Ordenar por"
        },
        titles: [],
        artigos:[]
      }
    },
    created () {
      // this.artigos =this.getAllArtigos;
      this.artigos = this.selectedType == 1 ? this.getAllArtigos.filter(title => title.tipo == "anel") : this.getAllArtigos.filter(title => title.tipo == "aliança");
      this.generos = this.getAllGenres.map(genre => genre.descricao);
      
    },
    computed: {
      ...mapGetters(["getAllArtigos", "getAllGenres"]),
      filteredTitles() {
        
        let filterResult = [...this.artigos];

        // Barra de pesquisa
        if (this.filters.search != "") {
          filterResult = filterResult.filter(peca => peca.titulo.toLowerCase().includes(this.filters.search.toLowerCase()));
        }

        // Materiais
        if (this.filters.materials != "All" && this.filters.materials != "Materiais") {
          filterResult = filterResult.filter(peca => peca.materiaPrima == this.filters.materials);
        }

        
        
        return filterResult;
      },
      
    },
    methods: {
      resetMostrar() {
        this.mostrar = 12;
      }
    },
    watch: {
      selectedType() {
        this.artigos = this.selectedType == 1 ? this.getAllArtigos.filter(title => title.tipo == "anel"):
        this.artigos = this.selectedType == 2 ? this.getAllArtigos.filter(title => title.tipo == "aliança"):
        this.artigos = this.selectedType == 3 ? this.getAllArtigos.filter(title => title.tipo == "brinco"):
        this.artigos = this.selectedType == 4 ? this.getAllArtigos.filter(title => title.tipo == "colar"):
        this.artigos = this.selectedType == 5 ? this.getAllArtigos.filter(title => title.tipo == "medalha"):
        this.artigos = this.selectedType == 6 ? this.getAllArtigos.filter(title => title.tipo=="pulseira"):this.getAllArtigos.filter(title => title.tipo=="escrava")
        
      }
    }
  }

</script>

<style scoped>
  .filtros{
    padding: 0px 145px;
  }
  .catalog-div{
    background-color:#f8f9fa;
  }
  a {
    text-decoration: none;
    color: black;
  }

  .default-type,
  .active-type {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 2px solid;
    display: flex;
    justify-content: center;
  }

  .default-type {
    color: black;
  }

  .active-type {
    color: orange;
  }

  select {
    /* Reset */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    outline: 0;
    /* Personalize */
    width: 100%;
    height: 100%;
    padding-left: 10px;
    /* padding: 0 4em 0 1em; */
    background: url("../assets/images/chevron-down-solid.svg") no-repeat right 0.8em center/0.8em;
    color: black;
    border-radius: 30px;
    box-shadow: 0 0 .5em 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  select option {
    background-color: white !important;
    color: black !important;
  }

  select:focus {
    outline: none;
  }

  select::-ms-expand {
    display: none;
  }

  

  .carousel-wrapper {
    margin: 0;
    color: #ecf0f1;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .carousel-wrapper h1,
  .carousel-wrapper p {
    text-align: center;
  }

  .carousel-wrapper a:link,
  .carousel-wrapper a:hover,
  .carousel-wrapper a:active,
  .carousel-wrapper a:visited {
    transition: color 150ms;
    color: #95a5a6;
    text-decoration: none;
  }

  .carousel-wrapper a:hover {
    color: #7f8c8d;
    text-decoration: underline;
  }

  .row-carousel {
    overflow-x: scroll;
    scroll-behavior: smooth;
    width: 100%;
  }

  .row__inner {
    transition: 450ms transform;
    font-size: 0;
    white-space: nowrap;
    padding-bottom: 10px;
  }

  .tile {
    position: relative;
    display: inline-block;
    max-width: 200px;
    width: 100%;
    margin-right: 10px;
    font-size: 20px;
  }

  .tile-custom {
    position: relative;
    display: inline-block;
    font-size: 20px;
  }

  .tile__media img {
    max-width: 200px;
    width: 100%;
    height: 296.28px;
  }

  .tile__img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 5px;
  }

  .tile:hover .tile__details {
    opacity: 1;
  }

  .tile-custom:hover .tile__details {
    opacity: 1;
  }

  .row__inner:hover .tile:hover {
    opacity: 1;
  }

  .navigation {
    font-size: 1.5em;
    color: white;
    display: flex;
    justify-content: flex-end;
  }

  .nav-left {
    color: gray;
  }

  .nav-left:hover,
  .nav-right:hover {
    cursor: pointer;
  }

  .tile__details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
    transition: 450ms opacity;
    border-radius: 5px;
  }

  .tile__details:after {
    margin-top: -25px;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    border: 3px solid #ecf0f1;
    line-height: 50px;
    text-align: center;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .tile__details:before:hover {
    cursor: pointer;
  }

  .tile:hover .tile__details {
    opacity: 1;
  }

  .tile-custom:hover .tile__details {
    opacity: 1;
  }

  .tile__details .quiz-card-rating {
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 35px;
    height: 35px;
    border: 2px solid var(--verde);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: .85em;
    color: var(--verde);
  }

  .tile__details .quiz-card-title {
    white-space: normal;
  }

  .tile__details .quiz-card-play {
    position: absolute;
    top: 130px;
    left: 82px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 50%;
  }

  .tile__details .quiz-card-play:hover {
    cursor: pointer;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  form ::placeholder {
    color: rgb(184, 184, 184) !important;
    opacity: 1;
  }

  form :-ms-input-placeholder {
    color: rgb(184, 184, 184);
  }

  form ::-ms-input-placeholder {
    color: rgb(184, 184, 184);
  }

  

  form button {
    border: none !important;
    background: #f8f9fa;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    padding: 0 15px 0 15px;
    box-shadow: 0 0 .5em 0 rgba(0, 0, 0, 0.2);
  }

  form i {
    color: black;
  }

  form input,
  form input:focus {
    color: black;
    outline: none !important;
    border: none;
    outline: none;
    border-radius: 30px;
    box-shadow: 0 0 .5em 0 rgba(0, 0, 0, 0.2);
    padding: 10px 15px 10px 15px;
    background-color:#f8f9fa;
  }

  @media (max-width: 419px) {
    .tile {
      max-width: 150px;
    }

    .tile__media img {
      height: 222.22px;
    }

    .tile__details .quiz-card-play {
      top: 96px;
      left: 58px;
    }

    .jumbo-wrapper {
      min-height: 425px;
      max-height: 600px;
      height: 100% !important;
    }

    .jumbotron {
      padding: .5rem !important;
    }
  }

  @media (max-width: 1168px) {
    .filtros{
      padding: 0px 50px;
     }
  }

  @media (max-width: 767px) {
    .nav-left,
    .nav-right {
      display: none;
    }
    
  }
</style>